:root {
  --zoom-level-0-subtext: 18px;
  --zoom-level-0-text: 14px;
  
  
  --zoom-level-1-text: 14px;
  --zoom-level-1-subtext: 16px;
  --zoom-level-1-appointment-sm: 9px;
  --zoom-level-1-title: 12px;
  
  --zoom-level-2-text: 10px;
  --zoom-level-2-subtext: 12px;
  --zoom-level-2-appointment-sm: 9px;
  --zoom-level-2-title: 12px;

}
@media screen and (min-width: 1700px) {
:root {
  --zoom-level-0-subtext: 20px;
  --zoom-level-0-text: 16px;


  --zoom-level-1-text: 16px;
  --zoom-level-1-subtext: 18px;
  --zoom-level-1-appointment-sm: 12px;
  --zoom-level-1-title: 14px;
  
  
  --zoom-level-2-text: 16px;
  --zoom-level-2-subtext: 18px;
  --zoom-level-2-appointment-sm: 15px;
  --zoom-level-2-title: 18px;
}
}

@font-face {
  font-family: 'Digital-7';
  src: url(./fonts/digital-7.woff) format('truetype');
}
@font-face {
  font-family: 'Montserrat-800';
  src: url(./fonts/Montserrat-ExtraBold.ttf) format('truetype');
}
@media print {
  div.page-break {
    break-inside: avoid;
    page-break-inside: avoid;
  }
}
html {
  font-size: 16px;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  background-color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;
  height: 5px;
  ;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #88888856;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #88888856;
}

#root {
  height: 100%;
  background: white;
}

p {
  margin: 0;
}

button {
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
}

button:hover {
  cursor: pointer;
}


button,
.prevent-select {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

.ant-modal .ant-modal-content {
  padding: 0;
  background-color: #F6F6F6;
}

.ant-modal .ant-modal-content .ant-modal-header {
  display: none;
}

.ant-tabs .ant-tabs-content-holder {
  display: flex;
  flex-direction: column;
}

.ant-tabs .ant-tabs-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.ant-tabs .ant-tabs-tabpane {
  flex: 1;
  padding: 0 1.5rem;
}

.ant-input {
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  line-height: 1.5;

}

.ant-input-affix-wrapper {
  height: 46px;
}

.ant-input-affix-wrapper>input.ant-input {
  padding-left: 8px;
}

/* .ant-table-thead th.ant-table-cell {
  background: #CECECE;
} */
.ant-table-wrapper .ant-table-thead>tr>th,
.ant-table-wrapper .ant-table-thead>tr>th p {
  background: #CECECE;
  color: #1D2129;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.ant-table-wrapper .ant-table-thead>tr>th::before {
  content: none !important;
}

.ant-table-wrapper .ant-table-tbody>tr>td,
.ant-table-wrapper .ant-table-tbody>tr>td p {
  overflow: hidden;
  color: #1D2129;
  text-overflow: ellipsis;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ant-table-wrapper .ant-table-tbody>tr>td p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  flex: 1 0 0;
}


.ant-table-wrapper .ant-table-pagination.ant-pagination {
  margin: 24px 0;
}

.ant-pagination .ant-pagination-item {
  min-width: 50px;
  height: 50px;
}

.ant-pagination .ant-pagination-item a {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ant-pagination .ant-pagination-item-active {
  border-radius: 3.333px;
  background: #232F3E;
}

.ant-pagination .ant-pagination-item-active a {
  color: #FFF;
}

.ant-pagination .ant-pagination-prev,
.ant-pagination .ant-pagination-next,
.ant-pagination .ant-pagination-jump-prev,
.ant-pagination .ant-pagination-jump-next {
  min-width: 50px;
  height: 50px;
}

.ant-pagination .ant-pagination-prev a,
.ant-pagination .ant-pagination-next a,
.ant-pagination .ant-pagination-jump-prev a,
.ant-pagination .ant-pagination-jump-next a {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.ant-input::placeholder,
input::placeholder {
  color: #dadada;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ant-avatar.ant-avatar-lg {
  width: 50px;
  height: 50px;
}

.modal-max-height-unset.ant-modal .ant-modal-content .ant-modal-body {
  max-height: unset !important;
}

.ant-checkbox .ant-checkbox-inner {
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 2px;
  border: 2px solid #E5E6EB;
  background: #CECECE;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-checkbox .ant-checkbox-inner::after {
  width: 8px;
  height: 16px;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
  -ms-transform: rotate(45deg) scale(1) translate(-50%, -50%);
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #148CF1;
  border-color: #148CF1;
}

.checkbox-request-staff.ant-checkbox-wrapper {
  color: #148CF1;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  align-items: center;
}

.modal-custom-title .staff-info {
  display: flex;
  align-items: center;
  gap: 8px;
}

.modal-custom-title .staff-info .staff-name {
  color: #1D2129;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.modal-custom-title .staff-info .ant-avatar {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  z-index: 1;
  justify-content: center;
}

.modal-custom-title .staff-info .ant-avatar .ant-avatar-string {
  font-size: 20px;
}

.ant-picker-panel-container .ant-picker-panel-layout .ant-picker-panels .ant-picker-panel:last-child {
  display: none;
}

.ant-picker-dropdown .ant-picker-range-wrapper {
  zoom: 1.3;
}
.ant-picker-dropdown .ant-picker-range-wrapper .ant-picker-panel-layout .ant-picker-presets ul li{
  color: #1D2129;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 8px;
}
.ant-picker.ant-picker-range {
  height: 40px;
}
.ant-picker.ant-picker-range .ant-picker-input input {
  font-size: 1rem;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}